import React, { Fragment, useState } from 'react';
import SEO from '@codedrop/seo/esm/seo';
import Header from '@codedrop/header/esm/header';
import Layout from '@codedrop/layout/esm/layout';
import Heading from '@codedrop/typography/esm/heading';
import Cta from '@codedrop/cta/esm/cta';
import Footer from '@codedrop/footer/esm/footer';
import setHTML from '@codedrop/utils/esm/utils';
import data from '../data/skpr.json';
import underTheHoo from '../data/under-the-hood.json';
import HowItWorksAnimation from '../components/how-it-works/how-it-works-animation';
import HowItWorksPanel from '../components/how-it-works/how-it-works-panel';

const { seo, header, footer, cta } = data;
const { site, pageTitle, sections } = underTheHoo;

const UnderTheHoodPage = () => {
  const [animationStage, setAnimationStage] = useState('highlight-skpr');

  function handleIntersectionChange(animationIndex) {
    setAnimationStage(animationIndex);
  }

  return (
    <div>
      <SEO title={site.title} seo={seo} />
      <Header header={header} />
      <main>
        <Layout className="background--firework section--top-l how-it-works__section">
          <Heading tag="h1" headingClass="heading--special">
            {pageTitle}
          </Heading>
          <div className="how-it-works__grid">
            <div className="how-it-works__column">
              {sections.map((panel, index) => (
                <Fragment key={index}>
                  <HowItWorksAnimation
                    animid={index}
                    currentAnimationIsAbove={false}
                    currentAnimationStage={`highlight-${panel.animName}`}
                    isMobileOnly={true}
                  />
                  <HowItWorksPanel
                    index={`highlight-${panel.animName}`}
                    onIntersectionChange={handleIntersectionChange}
                  >
                    <img
                      src={`/${panel.image}`}
                      width={panel.imageWidth}
                      height={panel.imageHeight}
                      alt={''}
                    />
                    <Heading
                      tag="h3"
                      headingClass="heading--special heading--l text--secondary"
                    >
                      {setHTML(panel.title)}
                    </Heading>
                    {panel.content.map((item, index) => (
                      <Fragment key={index}>{setHTML(item)}</Fragment>
                    ))}
                  </HowItWorksPanel>
                </Fragment>
              ))}
            </div>
            <div className="how-it-works__animation">
              <HowItWorksAnimation
                animid={'desktop'}
                currentAnimationIsAbove={false}
                currentAnimationStage={animationStage}
              />
            </div>
          </div>
        </Layout>
        <Cta cta={cta} />
      </main>
      <Footer footer={footer} />
    </div>
  );
};

export default UnderTheHoodPage;
